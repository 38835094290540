// Abonnes.js

import React, { useEffect, useState } from "react";
import DataDisplayOptions from "../shared/DataDisplayOptions";
// Vues
import Abonnes_SenatAN from "./Abonnes_SenatAN";
import fetchRawData from "../shared/utils/fetchRawData";
import transformData from "../shared/utils/transformData";
import downloadData from "../shared/utils/downloadData";

function Abonnes() {
  const [data, setData] = useState([]);
  const [displayType, setDisplayType] = useState("senatAN"); // Vue par défaut : "Sénat vs. AN"

  const local_CORSAnywhere = "http://localhost:8080/";
  const external_CORSAnywhere = "https://cors-anywhere.herokuapp.com/";
  const noProxy = "";
  const proxyUrl = local_CORSAnywhere;
  const rawDataUrl =
    "https://1npd7l897a.execute-api.eu-west-3.amazonaws.com/dev/mensuel/abonnes";

  const fetchData = async () => {
    try {
      const rawData = await fetchRawData(proxyUrl, rawDataUrl);
      const transformedData = await transformData(proxyUrl, rawData);
      setData(transformedData);
    } catch (error) {
      console.error(
        "Erreur lors de la récupération ou de la transformation des données :",
        error
      );
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleDownloadRawJson = async () => {
    try {
      const rawData = await fetchRawData(proxyUrl, rawDataUrl);
      downloadData(rawData, "abonnes_data_brut.json");
    } catch (error) {
      console.error("Erreur lors du téléchargement du JSON brut :", error);
    }
  };

  const handleDownloadTransformedJson = async () => {
    try {
      const rawData = await fetchRawData(proxyUrl, rawDataUrl);
      const transformedData = await transformData(proxyUrl, rawData);
      downloadData(transformedData, "abonnes_data_transforme.json");
    } catch (error) {
      console.error(
        "Erreur lors du téléchargement du JSON transformé :",
        error
      );
    }
  };

  const handleSelectDisplayType = (type) => {
    setDisplayType(type);
  };

  return (
    <div>
      <h2>Page Abonnés</h2>
      <DataDisplayOptions
        onDownloadRawJson={handleDownloadRawJson}
        onDownloadTransformedJson={handleDownloadTransformedJson}
        onSelectDisplayType={handleSelectDisplayType}
        dataType="Abonnés (suivi mensuel)"
      />

      {/* Rendu conditionnel pour chaque type de vue */}
      {displayType === "senatAN" && <Abonnes_SenatAN data={data} />}

      <p>Contenu de la page Abonnés</p>
    </div>
  );
}

export default Abonnes;
