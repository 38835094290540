import React, { useEffect, useState } from "react";

function TableauGS() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedResource, setSelectedResource] = useState("/mensuel");

  const resources = [
    { label: "Chiffres Mensuels", path: "/mensuel" },
    { label: "Abonnés Mensuels", path: "/mensuel/abonnes" },
    { label: "Vues Mensuelles", path: "/mensuel/vues" },
    { label: "Vues Instagram", path: "/mensuel/vuesINSTA" },
    { label: "Vues LinkedIn", path: "/mensuel/vuesLINK" },
    { label: "Presse", path: "/mensuel/presse" },
  ];

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await fetch(
          `https://cors-anywhere.herokuapp.com/https://1npd7l897a.execute-api.eu-west-3.amazonaws.com/dev${selectedResource}`
        );
        if (!response.ok) {
          throw new Error(`Erreur : ${response.status}`);
        }
        const result = await response.json();
        setData(result);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [selectedResource]);

  const handleResourceChange = (event) => {
    setSelectedResource(event.target.value);
  };

  if (loading) return <p>Chargement des données...</p>;
  if (error) return <p>Erreur lors de la récupération des données : {error}</p>;

  return (
    <div>
      <label htmlFor="resourceSelect">Choisissez une ressource : </label>
      <select
        id="resourceSelect"
        onChange={handleResourceChange}
        value={selectedResource}
      >
        {resources.map((resource) => (
          <option key={resource.path} value={resource.path}>
            {resource.label}
          </option>
        ))}
      </select>

      <table>
        <thead>
          <tr>
            {data[0] &&
              data[0].map((header, index) => <th key={index}>{header}</th>)}
          </tr>
        </thead>
        <tbody>
          {data.slice(1).map((row, rowIndex) => (
            <tr key={rowIndex}>
              {row.map((cell, cellIndex) => (
                <td key={cellIndex}>{cell}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default TableauGS;
