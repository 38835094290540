import React, { useState, useEffect } from "react";
import { Amplify } from "aws-amplify";
import { get } from "@aws-amplify/api-rest";
import awsconfig from "../../../aws-exports";

Amplify.configure(awsconfig);

function TestApis() {
  const [message, setMessage] = useState("");
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Utilisez l'URL avec le préfixe CORS Anywhere
        const proxyUrl = "https://cors-anywhere.herokuapp.com/";
        const apiUrl = `${proxyUrl}https://4epy68vnoe.execute-api.eu-west-3.amazonaws.com/dev/test`;

        // Utilisez fetch directement car 'get' ne fonctionne pas avec cette URL complète
        const response = await fetch(apiUrl);
        const data = await response.text(); // ou response.json() si c'est un objet JSON

        setMessage(data);
      } catch (err) {
        console.error("Erreur lors de l'appel de l'API :", err);
        setError("Erreur lors de la récupération du message");
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <h3>Réponse de TestApis :</h3>
      {error ? <p>{error}</p> : <p>{message}</p>}
    </div>
  );
}

export default TestApis;
