import React, { useState } from "react";
import { Amplify } from "aws-amplify";
import awsconfig from "./aws-exports";
import { withAuthenticator } from "@aws-amplify/ui-react"; // pas utilisé en localhost

import Menu from "./components/layout/Menu";
import Content from "./components/layout/Content";
import "./styles/global.css";

Amplify.configure(awsconfig);

function App() {
  // Ajout de l'état pour gérer la page sélectionnée
  const [selectedPage, setSelectedPage] = useState("Accueil");

  // Fonction pour mettre à jour la page en fonction de la sélection
  const handleSelectPage = (page) => {
    setSelectedPage(page);
  };

  return (
    <div className="app-container">
      {/* Passer handleSelectPage et selectedPage à Menu pour gérer la navigation */}
      <Menu onSelectPage={handleSelectPage} selectedPage={selectedPage} />

      {/* Passer la page sélectionnée à Content pour afficher le bon contenu */}
      <Content page={selectedPage} />
    </div>
  );
}

// export default withAuthenticator(App);
export default App;
