// utils/transformData.js

const transformData = async (proxyUrl, rawData) => {
  const transformedApiUrl = `${proxyUrl}https://hq6fg538c3.execute-api.eu-west-3.amazonaws.com/dev/transform_abonnes`;
  try {
    console.log(
      "Début de la transformation des données via URL :",
      transformedApiUrl
    );
    const response = await fetch(transformedApiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
      },
      body: JSON.stringify(rawData),
    });

    if (!response.ok) {
      throw new Error(
        `Erreur lors de la transformation des données : ${response.status}`
      );
    }
    const transformedData = await response.json();
    console.log("Données transformées :", transformedData);
    return transformedData;
  } catch (error) {
    console.error("Erreur dans transformData :", error);
    throw error;
  }
};

export default transformData;
