import React from "react";
import Header from "./Header";
import TableauGS from "../RS/tests/TableauGS";
import TestApis from "../RS/tests/TestApis";
import Abonnes from "../RS/abonnes/Abonnes";

function Content({ page }) {
  const renderContent = () => {
    switch (page) {
      case "Accueil":
        return <p>Bienvenue sur la page d'accueil !</p>;
      case "Test GS":
        return <TableauGS />;
      case "Tests API":
        return <TestApis />;
      case "Abonnés":
        return <Abonnes />;
      case "Publications":
        return <p>Contenu pour les Publications</p>;
      case "Vues":
        return <p>Contenu pour les Vues</p>;
      default:
        return <p>Page non trouvée</p>;
    }
  };

  return (
    <main className="content">
      {/* <Header pageTitle={page} />{" "} */}
      {/* Affiche le titre de la page sélectionnée */}
      {renderContent()}
    </main>
  );
}

export default Content;
