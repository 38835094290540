// table_v1.js

import React from "react";
import comparisons from "./comparisons";

function TableV1({ data, comparisonKey }) {
  console.log("Données reçues dans TableV1 :", data);

  if (!Array.isArray(data) || data.length === 0) {
    return <p>Chargement des données...</p>;
  }

  const comparisonData = comparisons[comparisonKey] || {};
  const senatKey = comparisonData.Sénat;
  const anKey = comparisonData.AN;

  return (
    <div>
      <h3>Tableau des abonnés</h3>
      <table>
        <thead>
          <tr>
            <th>Mois</th>
            <th>Total {comparisonKey} Sénat</th>
            <th>Total {comparisonKey} AN</th>
          </tr>
        </thead>
        <tbody>
          {data.map((monthData, index) => {
            const senatValue = monthData.plateformes[senatKey]?.abonnés || 0;
            const anValue = monthData.plateformes[anKey]?.abonnés || 0;

            const maxValue = Math.max(senatValue, anValue);

            return (
              <tr key={index}>
                <td>{monthData.Mois}</td>
                <td
                  style={{
                    fontWeight: senatValue === maxValue ? "bold" : "normal",
                  }}
                >
                  {senatValue.toLocaleString("fr-FR")}
                </td>
                <td
                  style={{
                    fontWeight: anValue === maxValue ? "bold" : "normal",
                  }}
                >
                  {anValue.toLocaleString("fr-FR")}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default TableV1;
