import React from "react";
import logo from "../../images/tablemolle.png";

function Menu({ onSelectPage, selectedPage }) {
  return (
    <aside className="menu">
      <img
        src={logo}
        alt="logo"
        className="rotating-logo"
        style={{
          width: "40%",
          borderRadius: "20px",
        }}
      />
      <nav>
        <ul>
          <li
            onClick={() => onSelectPage("Accueil")}
            className={selectedPage === "Accueil" ? "active" : ""}
          >
            Accueil
          </li>
          <li
            onClick={() => onSelectPage("Test GS")}
            className={selectedPage === "Test GS" ? "active" : ""}
          >
            Test GS
          </li>
          <li
            onClick={() => onSelectPage("Tests API")}
            className={selectedPage === "Tests API" ? "active" : ""}
          >
            Tests API
          </li>
          <li
            onClick={() => onSelectPage("Abonnés")}
            className={selectedPage === "Abonnés" ? "active" : ""}
          >
            Abonnés
          </li>
          <li
            onClick={() => onSelectPage("Publications")}
            className={selectedPage === "Publications" ? "active" : ""}
          >
            Publications
          </li>
          <li
            onClick={() => onSelectPage("Vues")}
            className={selectedPage === "Vues" ? "active" : ""}
          >
            Vues
          </li>
        </ul>
      </nav>
    </aside>
  );
}

export default Menu;
