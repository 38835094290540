// Import des éléments de base pour créer le graphique avec Recharts
import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import comparisons from "./comparisons";

// Composant personnalisé pour afficher une étiquette avec la valeur directement sur le point du graphique
function CustomDotWithLabel({ cx, cy, payload, color, dataKey, otherDataKey }) {
  // Récupère la valeur du point actuel et celle de l'autre série pour la comparaison
  const value = payload[dataKey];
  const otherValue = payload[otherDataKey];

  // Détermine si l'étiquette doit être placée au-dessus ou en dessous du point en fonction de la comparaison
  const isAbove = value > otherValue;

  return (
    <g>
      {/* Cercle représentant le point sur la ligne */}
      <circle cx={cx} cy={cy} r={6} fill={color} />

      {/* Texte de l'étiquette affichant la valeur, positionné au-dessus ou en dessous du point */}
      <text
        x={cx}
        y={cy}
        dy={isAbove ? -20 : 25} // Décalage vertical basé sur la comparaison des valeurs
        textAnchor="middle"
        fontSize={14}
        fontWeight="bold"
        fill={color}
      >
        {value.toLocaleString("fr-FR")}{" "}
        {/* Formatage en chaîne de caractères avec séparateur de milliers */}
      </text>
    </g>
  );
}

function TimelineChart({ data, comparisonKey }) {
  console.log("Données reçues dans TimelineChart :", data);

  // Affiche un message de chargement si les données ne sont pas encore disponibles
  if (!Array.isArray(data) || data.length === 0) {
    return <p>Chargement des données...</p>;
  }

  // Récupère les clés de comparaison pour le Sénat et l'AN en fonction de la clé de comparaison sélectionnée
  const comparisonData = comparisons[comparisonKey] || {};
  const senatKey = comparisonData.Sénat;
  const anKey = comparisonData.AN;

  // Transformation des données : crée un tableau de données formaté pour le graphique
  const transformedData = data.map((monthData) => ({
    Mois: monthData.Mois, // Nom du mois
    Sénat: monthData.plateformes[senatKey]?.abonnés || 0, // Valeur pour le Sénat
    AN: monthData.plateformes[anKey]?.abonnés || 0, // Valeur pour l'Assemblée Nationale
  }));

  // Calcul des valeurs minimales et maximales pour l'axe des Y en fonction des données transformées
  const allValues = transformedData.flatMap((row) => [row["Sénat"], row["AN"]]);
  const yMin = Math.floor(Math.min(...allValues) / 10000) * 10000;
  const yMax = Math.ceil(Math.max(...allValues) / 100000) * 100000;

  return (
    // Conteneur réactif qui ajuste la taille du graphique automatiquement
    <ResponsiveContainer width="100%" height={400}>
      <LineChart
        data={transformedData}
        margin={{ top: 10, right: 30, left: 10, bottom: 10 }}
      >
        {/* Grille de fond du graphique */}
        <CartesianGrid strokeDasharray="3 3" />

        {/* Axe X avec les noms des mois */}
        <XAxis dataKey="Mois" tick={{ fontSize: 12 }} />

        {/* Axe Y avec une échelle ajustée et un formatage des valeurs en milliers */}
        <YAxis
          domain={[yMin, yMax]} // Limites dynamiques pour l'axe Y
          tickFormatter={(value) => value.toLocaleString("fr-FR")}
          tick={{ fontSize: 12 }}
        />

        {/* Tooltip désactivé car les valeurs sont affichées directement sur le graphique */}
        <Tooltip content={<></>} />

        {/* Légende du graphique */}
        <Legend />

        {/* Ligne pour les données du Sénat */}
        <Line
          type="monotone"
          dataKey="Sénat"
          stroke="#d9534f" // Couleur de la ligne pour le Sénat
          strokeWidth={3}
          activeDot={
            <CustomDotWithLabel
              color="#d9534f" // Couleur de l'étiquette
              dataKey="Sénat" // Clé des données pour le Sénat
              otherDataKey="AN" // Clé des données pour l'Assemblée Nationale
            />
          }
        />

        {/* Ligne pour les données de l'Assemblée Nationale */}
        <Line
          type="monotone"
          dataKey="AN"
          stroke="#0066cc" // Couleur de la ligne pour l'Assemblée Nationale
          strokeWidth={3}
          activeDot={
            <CustomDotWithLabel
              color="#0066cc" // Couleur de l'étiquette
              dataKey="AN" // Clé des données pour l'Assemblée Nationale
              otherDataKey="Sénat" // Clé des données pour le Sénat
            />
          }
        />
      </LineChart>
    </ResponsiveContainer>
  );
}

export default TimelineChart;
