// ComparisonSelector.js

import React from "react";
import comparisons from "./comparisons";

function ComparisonSelector({ selectedComparison, setSelectedComparison }) {
  return (
    <div style={{ marginBottom: "20px", textAlign: "center" }}>
      <label htmlFor="comparisonSelect" style={{ marginRight: "10px" }}>
        Choisissez la comparaison :
      </label>
      <select
        id="comparisonSelect"
        value={selectedComparison}
        onChange={(e) => setSelectedComparison(e.target.value)}
        style={{
          padding: "5px 10px",
          fontSize: "14px",
          borderRadius: "5px",
          border: "1px solid #ccc",
        }}
      >
        {Object.keys(comparisons).map((key) => (
          <option key={key} value={key}>
            {key}
          </option>
        ))}
      </select>
    </div>
  );
}

export default ComparisonSelector;
