// utils/fetchRawData.js

const fetchRawData = async (proxyUrl, rawDataUrl) => {
  try {
    console.log(
      "Début de la récupération des données brutes via URL :",
      `${proxyUrl}${rawDataUrl}`
    );
    const response = await fetch(`${proxyUrl}${rawDataUrl}`, {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
      },
    });

    console.log("Statut de la réponse :", response.status);
    if (!response.ok) {
      throw new Error(
        `Erreur lors de la récupération des données brutes : ${response.status}`
      );
    }
    const rawData = await response.json();
    console.log("Données brutes (premières lignes) :", rawData.slice(0, 5));
    return rawData;
  } catch (error) {
    console.error("Erreur dans fetchRawData :", error);
    throw error;
  }
};

export default fetchRawData;
