// DataDisplayOptions.js

import React from "react";

// Liste des vues disponibles, avec une clé "type" et un "label" pour chaque vue
const views = [
  { type: "senatAN", label: "Sénat vs. AN" },
  // Ajoutez ici d'autres vues si besoin, par exemple :
  // { type: "nouvelleVue", label: "Nouvelle Vue" },
];

function DataDisplayOptions({
  onDownloadRawJson,
  onDownloadTransformedJson,
  onSelectDisplayType,
  dataType,
}) {
  return (
    <div className="data-display-options">
      <span>Vues :</span>

      {/* Génère dynamiquement les liens pour les vues en fonction de la liste `views` */}
      {views.map((view, index) => (
        <span
          key={view.type}
          onClick={() => onSelectDisplayType(view.type)}
          style={{
            cursor: "pointer",
            color: "blue",
            textDecoration: "underline",
            margin: "0 5px",
          }}
        >
          {view.label}
        </span>
      ))}

      <div style={{ marginTop: "10px" }}>
        <span>Downloads :</span>
        <button onClick={onDownloadRawJson} style={{ margin: "0 5px" }}>
          JSON brut
        </button>
        <button onClick={onDownloadTransformedJson} style={{ margin: "0 5px" }}>
          JSON transformé
        </button>
      </div>
    </div>
  );
}

export default DataDisplayOptions;
