// Abonnes_SenatAN.js

import React, { useState } from "react";
import ComparisonSelector from "./ComparisonSelector";
import TimelineChart from "./timeline_chart";
import TableV1 from "./table_v1";

function Abonnes_SenatAN({ data }) {
  const [selectedComparison, setSelectedComparison] = useState("Total abonnés");

  // Obtenir la date actuelle
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth(); // Janvier = 0, Février = 1, etc.

  // Transformation en tableau avec filtrage des mois
  const transformedData = Object.entries(data)
    .filter(([month]) => {
      // Extraction de l'année et du mois depuis `month` (ex: "Sep 23" => "Sep" et "23")
      const [monthStr, yearStr] = month.split(" ");
      const monthIndex = new Date(Date.parse(`${monthStr} 1, 2000`)).getMonth();
      const year = parseInt(`20${yearStr}`, 10);

      // Filtrer pour ne garder que les mois antérieurs ou égaux au mois actuel
      // et ceux qui contiennent des données
      return (
        (year < currentYear ||
          (year === currentYear && monthIndex <= currentMonth)) &&
        data[month] &&
        Object.keys(data[month].plateformes || {}).length > 0
      );
    })
    .map(([month, monthData]) => ({
      Mois: month,
      ...monthData,
    }));

  return (
    <div>
      <ComparisonSelector
        selectedComparison={selectedComparison}
        setSelectedComparison={setSelectedComparison}
      />
      <TimelineChart
        data={transformedData}
        comparisonKey={selectedComparison}
      />
      <TableV1 data={transformedData} comparisonKey={selectedComparison} />
    </div>
  );
}

export default Abonnes_SenatAN;
